<template>
    <div class="contenedor-orden-dia">
        <div class="orden-titulo">
            <h1>Sesión Ordinaria 43º</h1>
        </div>
        <div class="logos"></div>
        <div class="orden-body">
            <h3>CUADRAGÉSIMA SEGUNDA SESIÓN ORDINARIA DEL SISTEMA ESTATAL PARA PREVENIR, ATENDER, SANCIONAR Y ERADICAR LA VIOLENCIA CONTRA LAS MUJERES</h3>
            <p>A TRAVES DE LA PLATAFORMA VIRTUAL ZOOM, CUERNAVACA, MORELOS</p>
            <p>INICIO: <b>11:00 HORAS</b></p>
            <h3>ORDEN DEL DÍA</h3>
            <ol>
                <!-- 1. Número uno -->
                <li>BIENVENIDA A LAS Y LOS INTEGRANTES E INVITADOS DEL SISTEMA, DE LA SOCIEDAD CIVIL Y/O ACADEMIA, ASÍ COMO AUTORIDADES QUE ACOMPAÑAN, POR PARTE DEL <b>LICENCIADO PABLO OJEDA CÁRDENAS</b>, SECRETARIO DE GOBIERNO Y PRESIDENTE DEL SEPASE <b>(5 MINS)</b> </li>
                <!-- 2. Número 2 -->
                <li>PASE DE LISTA Y DECLARACIÓN DE QUÓRUM LEGAL, POR PARTE DE LA <b>ARQUITECTA FLOR DESSIRÉ LEÓN HERNÁNDEZ</b>, PRESIDENTA DEL INSTITUTO DE LA MUJER PARA EL ESTADO DE MORELOS Y SECRETARIA EJECUTIVA DEL SEPASE <b>(5 MINS)</b></li>
                <!-- 3. número 3 -->
                <li>LECTURA Y EN SU CASO APROBACIÓN DEL ORDEN DEL DÍA, POR PARTE DE LA <b>ARQUITECTA FLOR DESSIRÉ LEÓN HERNÁNDEZ</b>, PRESIDENTA DEL INSTITUTO DE LA MUJER PARA EL ESTADO DE MORELOS Y SECRETARIA EJECUTIVA DEL SEPASE <b>(5 MINS)</b> <a href="https://drive.google.com/file/d/1JJ0PkdzIaVF4QTjhXLFfGhp4_Bzh0wSu/view?usp=sharing" target="_blink">- Mostrar documento</a></li>
                <!-- 4. Número 4 -->
                <li>DISPENSA, APROBACIÓN Y FIRMA DEL ACTA CORRESPONDIENTE A LA SESIÓN ANTERIOR (CUADRAGÉSIMA PRIMERA), POR PARTE DE LA <b>ARQUITECTA FLOR DESSIRÉ LEÓN HERNÁNDEZ</b>, PRESIDENTA DEL INSTITUTO DE LA MUJER PARA EL ESTADO DE MORELOS Y SECRETARIA EJECUTIVA DEL SEPASE <b>(5 MINS)</b> </li>
                <!-- 5. Número 5 -->
                <li>SEGUIMIENTO DE LOS ACUERDOS EMANADOS DE LAS SESIONES DEL SISTEMA ESTATAL PARA PREVENIR, ATENDER, SANCIONAR Y ERRADICAR LA VIOLENCIA CONTRA LAS MUJERES, POR PARTE DE LA <b>ARQUITECTA FLOR DESSIRÉ LEÓN HERNÁNDEZ</b>, PRESIDENTA DEL INSTITUTO DE LA MUJER PARA EL ESTADO DE MORELOS Y SECRETARIA EJECUTIVA DEL SEPASE <b> (5 MINS)</b> </li>
                <!--6.  número 6 -->
                <li>PRESENTACIÓN DE LOS AVANCES DE LA PROPUESTA DE PROFESIONALIZACIÓN DEL PERSONAL ADSCRITO A LA ADMINISTRACIÓN PÚBLICA ESTATAL, POR PARTE DE LA<b>ARQUITECTA FLOR DESSIRÉ LEÓN HERNÁNDEZ</b>, PRESIDENTA DEL INSTITUTO DE LA MUJER PARA EL ESTADO DE MORELOS Y SECRETARIA EJECUTIVA DEL SEPASE <b> (5 MINS)</b></li>

                <!-- 7. número 7 -->
                <li>PRESENATACIÓN DEL ESTATUS QUE GUARDA LA CONVOCATORIA PARA QUE ORGANIZACIONES DE LA SOCIEDAD CIVIL Y DE LA ACADEMÍA, CON VOCACIÓN Y EXPERIENCIA SOBRE LOS DERECHOS HUMANOS Y LA PREVENCIÓN, ATENCIÓN, SANCIÓN Y ERRADICACIÓN DE LA VIOLENCIA DE GÉNERO, PARA INTEGRARSE COMO INVITADAS E INVITADOS A LOS TRABAJOS DEL SEPASE, ASÍ COMO APROBACIÓN PARA AMPLIAR EL PLAZO, POR PARTE DE LA <b>ARQUITECTA FLOR DESSIRÉ LEÓN HERNÁNDEZ</b> PRESIDENTA DEL INSTITUTO DE LA MUJER PARA EL ESTADO DE MORLEOS Y SECRETARIA EJECUTIVA DEL SEPASE.  <a href="https://drive.google.com/file/d/1xXmCmXYJD1PvaxSImrRLmBwjpjm0u4yj/view?usp=sharing" target="_blank">- Mostrar documento</a> <b> (5 MINS) </b></li>

                <!--8.  número 8 -->
                <li>PRESENTACIÓN Y EN SU CASO APROBACIÓN PARA REALIZAR LA FE DE ERRATAS A LA FICHA DE INDICADOR 2.1 CORRESPONDIENTE AL EJE DE ATENCIÓN, DEL PEPASE, POR PARTE DE LA <b>ARQUITECTA FLOR DESSIRÉ LEÓN HERNÁNDEZ</b>, PRESIDENTA DEL INSTITUTO DE LA MUJER PARA EL ESTADO DE MORELOS Y SECRETARIA EJECUTIVA DEL SEPASE <b>(5 MINS)</b> <a href="https://drive.google.com/file/d/1SyfvoioRrTjiimHRiXyZGHRdmA4GsNqq/view?usp=sharing" target="_blank">- Mostrar documento</a></li>

                <!-- 9. número 9 -->
                <li>PRESENTACIÓN DEL INFORME DE CUMPLIMIENTO Y SEGUIMIENTO DEL PRIMER INFORME TRIMESTRAL DEL PEPASE, POR PARTE DE LA <b>ARQUITECTA FLOR DESSIRÉ LEÓN HERNÁNDEZ</b>, PRESIDENTA DEL INSTITUTO DE LA MUJER PARA EL ESTADO DE MORELOS Y SECRETARIA EJECUTIVA DEL SEPASE <b>(5 MINS)</b> </li>

                <!-- 10. número 10 -->
                 <li>PRESENTACIÓN DE LOS PROYECTOS FEDERALES A IMPLEMENTAR DURANTE EL AÑO 2021, ACARGO DE: <b>(10 MINS)</b>
                    <ol type="a" style="margin-left:20px">
                        <li>COMISIÓN ESTATAL DE SEGURIDAD PÚBLICA (CES)</li>
                        <li>INSTITUTO DE LA MUJER PARA EL ESTADO DE MORELOS (IMM)</li>
                        <li>SECRETARÍA DE GOBIERNO</li>
                        <li>FISCALÍA GENERAL DEL ESTADO DE MORELOS</li>
                    </ol>
                </li>

                
                <!-- Asuntos generales -->
                <li>ASUNTOS GENERALES <b>(5 MINS)</b></li>
                <!-- cierre de sesión -->
                <li>CLAUSURA DE LA SESIÓN POR PARTE DEL <b>LICENCIADO PABLO OJEDA CÁRDENAS</b>, SECRETARIO DE GOBIERNO <b>(5 MINS)</b></li>
            </ol>
            <!-- fin ordend el día -->
            <div class="orden-fecha-duracion">
                <h4>FECHA 11 DE AGOSTO DE 2021</h4>
                <h4>DURACIÓN DEL EVENTO: 60 MINS</h4>
            </div>
             <div class="regresar-dashboard">
                <button> <router-link to="/dashboard">Regresar</router-link></button>
            </div>
        </div>
       
    </div>
</template>